@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'MyWebFont';
    src: url('./assets/OpenSans.ttf') format('truetype'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.instagram{
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}

.facebook {
    background: #3b5998;
    background: linear-gradient(45deg, #3b5998 0%, #4a63b6 100%);
}

body{
    font-family: MyWebFont;
    @apply bg-gray-800
}

#root{
    height: 100%;
}

.yarl__slide_description{
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
}

figure{
    @apply max-w-2xl
}

